.customclass {
  margin: 0px !important;
  width: 18rem;
  height: 3rem;
}
.customclass:hover {
  background-color: #ededed;
  color: #919191 !important;
}
.ant-menu.ant-menu-light .ant-menu-item-selected.customclass {
  background-color: #ededed;
  color: #919191;
}
.customclass::after {
  border-right: none !important;
}
.ant-select.selectStyle {
  border-radius: 25px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 41px;
  padding: 0px 16px;
  /* color: #919191; */
  /* margin-top: 1px; */
  border-right: none;
  /* border: 1px solid #6cb4cc; */
  border-radius: 27px;
}
.ant-modal-footer {
  border-top: none;
  padding: 0px;
}

.about_applied_content {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 20px 20px 10px 20px;
}

.about_applied_Heading_style {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
}
