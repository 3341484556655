/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueMediumExtended.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

/* light */
@font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueMediumExtended.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueW0177BdCnObl.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
} */
