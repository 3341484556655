.side_bar {
  position: relative;
  max-height: 74vh;
  overflow: auto;
  /* border: 1px solid red; */
}

.chat_screen {
  position: relative;
  max-height: 84%;
  padding-top: 15px;
  padding-bottom: 50px;
  overflow: auto;
  /* border: 1px solid red; */
}
.create-community-button-chat{
  background-color: #005574 !important;
  border: 1px solid #005574 !important;
  border-radius: 20px;
  color: #fff !important;
  margin-top: 16px;
  /* width: 100%; */
  font-weight: bold;
}
.create-community-button-chat:hover {
  color: #005574 !important;
  background: #fff !important;
  border: 1px solid #005574;
}
#scrollStyle::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

#scrollStyle::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#scrollStyle::-webkit-scrollbar-thumb {
  background-color: rgba(73, 73, 73, 0.849);
  border-radius: 5px;
}
.custom_ChatUser{
  cursor: pointer;
  padding: 10px 38px; 
}

.custom_ChatUser:hover{
  background: rgb(213, 223, 244) !important;
}

.search_results_user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}
.search_results_user:hover{
  background: #d5dff4 ;
  border-radius: 3px;
}
.chat_screen_visiblity{
  padding: 1rem 1rem 1rem 0px;
}
.closeModal{
  background-color: #fff;
  border-radius: 20px;
  color: #005574;
}
.own_msg_card_delete{
  display: none;
  position: absolute;
  top: 1;
  right: -5px;
  z-index: 999;
}
.user_msg_card_delete{
  display: none;
  position: absolute;
  top: 1;
  left: 68px;
  z-index: 999;
}
.own_msg_card, .user_msg_card{
  overflow-wrap: break-word;
}
.own_msg_card:hover .own_msg_card_delete{
  display: block ;
}

.user_msg_card:hover .user_msg_card_delete{
  display: block ;
}
/* .own_msg_card:hover{
  color: red !important;
} */
.custom_divider_main .custom_divider{
  display: flex;
  justify-content: center;
}

.custom_divider{
  color:#00557454 !important;
  font-size:14px !important;
  font-weight:normal !important;
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after{
  width: 25% !important;
  border-top: 1px solid #00557454 !important;
}

@media (max-width:500px){
  .head_user_short_info{
    padding:14px 0px !important
  }
  .chat_screen_visiblity{
    padding: 16px 3px 16px 5px
  }
}

@media (max-width:420px){
  ._avatar_visibility_{
    display: none;
  }
  .own_msg_card{
    max-width: 240px !important;
  }
  .custom_ChatUser{
    padding:10px 6px
  }
  .chat-input-textArea::placeholder {
   white-space: nowrap;
   font-size: 1em !important;
   overflow-x:hidden
}
}

@media (max-width:375px){
  .own_msg_card{
    max-width: 200px !important;
  }
}

@media (max-width:320px){
  .bottom_message_card >.ant-card-body{
    padding:10px 4px !important
    }
    .chat-input-textArea{
      margin-right: 8px !important;
    }
    .upload_icon{
      margin:0px !important;
    }
    .smiley_icon{
      margin :0px 3px 0px 0px !important
    }
  .own_msg_card{
    max-width: 180px !important;
  }
  .custom_ChatUser{
    padding:10px 0px
  }
}
