/* .padding-card-network > .ant-card-body{
    padding:24px 15px !important;
} */

.network-button-cancel {
  text-align: center !important;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}
.network-button-cancel > .ant-btn-primary {
  color: #ef0606 !important;
  background: #fde7e7 !important;
  border-color: #fde7e7 !important;
  max-width: 100%;
  width: 100% !important;
  /* height: auto !important; */
  min-width: auto !important;
}
.network-button-cancel > .ant-btn-icon-only.ant-btn-lg {
  padding: 7px 2px !important;
}
.network-button-success {
  text-align: center !important;
}
.network-button-success > .ant-btn-primary {
  color: #73a45f !important;
  background: #bce3ad !important;
  border-color: #bce3ad !important;
  max-width: 100%;
  width: 100% !important;
  /* height: auto !important; */
  min-width: auto !important;
}

.ant-btn-round.ant-btn-lg {
  padding: 4px 12px;
}

.ant-card-body {
  padding: 10px;
}
.ant-select-selector {
  border-radius: 15px;
}

.btn-accept-req-modal,
.btn-accept-req-modal:hover {
  background: linear-gradient(rgb(60, 96, 134) 0%, rgb(53, 135, 196) 100%);
  border-color: #005574 100%;
  /* background-image: linear-gradient(#00536c, #007491) !important; */
}
.btn-accept-req-modal:focus {
  background: linear-gradient(rgb(60, 96, 134) 0%, rgb(53, 135, 196) 100%);
  border-color: #005574 100%;
  /* background-image: linear-gradient(#00536c, #007491) !important; */
}

.btn-accept-req-modal,
.btn-reject-req-modal {
  padding: 5px 20px !important;
  font-size: 18px;
  height: unset;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.network-card > .ant-card-body {
  padding: 0px !important;
}

.card-item{
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}

.network_btn{
  background-color: white !important;
  color: rgb(64, 130, 185) !important;
  border-color: rgb(64, 130, 185) !important;
  border-width: 1;
  width: 100% !important;
  font-size: 12px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-posts > .ant-spin-nested-loading > .ant-spin-container > .ant-list-items {
  background-color: transparent !important;
}

.rating-progress > .ant-progress-inner > .ant-progress-circle {
  transform: rotate(180deg);
}
.rating-progress > .ant-progress-inner > .ant-progress-text {
  font-size: 12px;
  font-weight: bold;
}
@media only screen and (max-width: 425px) {
  .btn-accept-req-modal,
  .btn-reject-req-modal {
    padding: 3px 16px !important;
    font-size: 14px;
    height: unset;
  }
}
