.listInfo {
  background-color: #ffffff;
  color: #919191;
  padding-left: 1rem;
}

.ant-progress-circle-trail {
  stroke: #cfcece;
}

.Education_column1{ 
  color: rgba(0, 0, 0, 0.4);
   width: 30% }
   .Column2_otherProfile{
    height: 100%; 
    width: 67%;
    flex: end
   
  }
  .otherProfile_Experience_Title{ height: 100%;
     width: 68%;
     flex:end;
     text-transform:capitalize}

   @media (max-width:320px) {
    .Education_column1{ 
      font-size: 90%;
      /* color: rgba(0, 0, 0, 0.4); */
       width: 34% 
      }
      .Column2_otherProfile{
        height: 100%; 
        font-size: 90%;
        width: 64%;
        flex: end
       }
       .otherProfile_Experience_Title{
        font-size: 90%;
        width: 64%;
       }
   }