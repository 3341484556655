.profile_pic {
    width: 200px;
    height: 200px;
    margin: auto;
}
.pic {
    width: 100%;
    border-radius: 50%;
    height: 200px;
}
.profile_name {
    display: block;
    color: #2f5597;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.domine_expert_text {
    display: block;
    color: #595959;
    font-weight: bold;
    font-size: 16px;
}
.domine_skill {
    color: #2f5597;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}
.rating_view_1 {
    background: white; 
    padding: 15px; 
    border-radius: 10px;
    width: 150px;
    height: 150px;
}
.rating_main {
    display: flex;
    margin: auto;
    width: 75%;
    justify-content: space-between;
}
.text_inner_circle {
    display: block;
    font-size: 10px;
    font-weight: bold;
    color: #2f5597;
}
.text_inner_circle_left {
    display: block;
    font-size: 4px;
    font-weight: bold;
    color: #2f5597;
}
.value_inner_circle {
    text-align: center;
    font-size: 22px;
    margin-bottom: 0px;
    font-weight: bold;
    color: #548235;
}
.value_inner_circle_left {
    text-align: center;
    font-size: 8px;
    margin-bottom: 0px;
    font-weight: bold;
    color: #548235;
}
.rating_main_2 {
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    /* width: 75%;
    margin: auto; */
}
.rating_main_22 {
    /* background: white; */
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 75%;
    margin: auto; */
}
.rating_applied_heading {
    padding: 10px;
    color: #586b8c;
    font-size: 18px;
    font-weight: bold;  
}
.rating_applied_heading  span {
    font-size: 16px;
    font-weight: bold;
}
.rating_applied_l1 {
    width: 20%;
}
.rating_applied_l1 span {
    font-size: 12px;
    text-transform: uppercase;
    color: #6581b2;
    font-weight: bold;
}
.rating_applied_rr {
    width: 80%;
    margin: auto;
    position: relative;
}
.rating_applied_rr_left {
    width: 65%;
    /* margin: auto; */
    position: relative;
    margin-left: 17px;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #0070c0 !important ;
}
.MuiLinearProgress-colorPrimary {
    background-color: #0070c0 !important ;
}
.overview {
    width: 50px;
    height: 50px;
    background: #2f5597;
    border-radius: 50%;
    text-align: center;
}
.col1 {
    border-left: 2px solid #bfbfbf;
    padding-left: 6px;
    padding-right: 6px;
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
}
.headerr {
    font-size: 14px;
    font-weight: bold;
    color: #2f5597;
    margin-bottom: 10px;
}
.percent_text {
    text-align: center;
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: bold;
    color: #2f5597;
}
.text_span {
    margin-left: 5px;
    font-size: 10px;
    font-weight: bold;
    color: #6581b2;
}
.header_icon {
    font-size: 30px;
    color: rgb(255, 255, 255);
    margin-top: 9px;
}
.headline {
    margin-top: 20px;
    /* height: 150px; */
}
.domine_icon {
    color: #fff;
    font-size: 40px;
}
.left_text {
    color: black;
    font-size: 14px;
    font-weight: 500;
}
.left_head {
    font-weight: bold;
    color: #6581b2;
    font-size: 16px;
    margin-bottom: 3px;
}
.ul_list {
    margin-top: 0px;
    background-color: white;
    padding-left: 30px;
    font-size: 1rem;
    line-height: 1.5;
    right: 0;
    list-style: none;
}
.ul_list li::before {
    margin-right: 10px;
    content: "• ";
    color: #2f5597;
    font-size: 35px;
    margin-top: -15px;
}
.ul_list li {
    display: flex;
}
.hr_line {
    height: 10px;
    background: rgb(165, 165, 165);
}
.circle-wrap {
    /* margin: 150px auto; */
    width: 150px;
    height: 150px;
    background: #fefcff;
    border-radius: 50%;
    /* border: 1px solid #cdcbd0; */
    margin-left: 30px;
  }
  .circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #227ded;
}
.circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }
  
  .mask.full,
  .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(135deg);
  }
  
  @keyframes fill{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  .circle-wrap .inside-circle {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    background: #d2eaf1;
    line-height: 120px;
    text-align: center;
    margin-top: 14px;
    margin-left: 14px;
    color: #1e51dc;
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
  }