@import "~antd/dist/antd.css";
/* .ant-btn-primary {
  background: transparent;
  background-color: transparent;
} */
/* .ant-btn {
  background-color: transparent;
  background: transparent;
} */
.ant-checkbox-checked::after {
  border-color: #0e76a8;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e76a8;
  border-color: #0e76a8;
}
