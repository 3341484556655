@import "~antd/dist/antd.css";

.rating_receive {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  background-color: #f1f0f5;
}
.rating_view {
  background: white;
  padding: 15px;
  border-radius: 10px;
  width: 150px;
  height: 150px;
}
/* .other_Rating > .profile_rating_flow > .rating_applied_l {
  width: 25%;
}
.other_Rating > .profile_rating_flow > .rating_applied_r {
  width: 75%;
} */

.dropdown{
  padding:20px
}
.npsChart {
  width: 100%;
  height: 91%;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nps_Chart_Analytics {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.npsChart_position {
  width: 70%;
  text-align: center;
}
.npsChart_analytics_responsive {
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
}
.rating_applied_l {
  width: 20%;
}
.rating_applied_r {
  width: 78%;
  margin: auto;
  position: relative;
}
.profile_rating_flow {
  display: flex;
  flex-direction: row;
}
.rating_applied_l_for_rating_view {
  width: 100%;
  margin-bottom: 16px;
}
.rating_applied_r_for_rating_view {
  width: 100%;
    justify-content: flex-start;
    /* margin-top: 10px; */
  position: relative;
}
.profile_rating_flow_for_rating_view{
  display: flex;
  padding:10px 22px 10px 10px !important;
  flex-direction: column;
}
.MuiLinearProgress-root{
  height: 2px !important;
}
.rating_applied_lx {
  flex: 2.7;
  height: 100%;
  display: flex;
  align-items: center;
}
.rating_applied_rx {
  flex: 7.3;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.profile_rating_flowx {
  width: 100%;
  margin-bottom: 12px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.avatar_rating_sent_received {
  margin-left: 0px;
}
/* .ant-modal-title2 {
    margin: 0;
    color: rgba(105, 105, 116, 1);
    font-weight: 500;
    font-size: 18px;
    word-wrap: break-word;
} */
.connection_btn {
  background-color: white;
  /* opacity: 0.5; */
  color: #4080c0;
  border-width: 2px;
  border: 1px solid #4080c0;
}
.MyBusiness_Download {
  background-color: white;
  /* opacity: 0.5; */
  color: #4080c0;
  border-width: 2px;
  border: 1px solid #4080c0;
  width: 39%;
  line-height: 29px;
}
.Dropdown_image_myProfile{
  width: 40px;height:40px
}
.MyBusiness_Update {
  width: 46%;
  background-color: white;
  /* opacity: 0.5; */
  color: #4080c0;
  border-width: 2px;
  border: 1px solid #4080c0;
}


.MyProfile_editPost_bottom_card {
  /* flex-direction: row;
  display: flex; */
  align-items: center;
  padding: 15px;
}
.MyProfile_editPost_buttonGroup {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 410px) {
  .profile_rating_flow_for_rating_view{
    padding:10px 28px 10px 10px !important
  }
  .post-connection-title{
    font-size: 13.25px;
  }
  .MyProfile_editPost_buttonGroup {
    width: 100%;
  }
  .MyProfile_editPost_buttonGroup > .e183m4hmemojiStyling > button {
    margin-right: 5px;
  }
  .MyProfile_editPost_bottom_card {
    padding: 0px;
  }
  .nps_Chart_Analytics {
    width: 100%;
    /* justify-content: none; */
    display: block;
    margin-top: -40px;
    margin-left: 30%;
    margin-bottom: 20px;
  }
  .npsChart_position {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .npsChart_analytics_responsive {
    display: flex;
    flex-direction: row;
  }
  .npsChart {
    display: block;
  }

  .connection_btn > span {
    font-size: 13.25px;
  }
  .MyBusiness_Download {
    width: 60%;
    font-size: 13.25px;
    line-height: 29px;
  }
  .MyBusiness_Update {
    width: 60%;
    font-size: 13.25px;
    margin-top: 10px;
  }
}

ul {
  margin: 0px !important;
  background-color: white;
  /* padding: 1rem; */
  /* font-size: 1rem; */
  line-height: 2.5;
  right: 0;
}

li a {
  font-size: 14px;
  color: #adadad;
}

.domain_skill {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 500;
}
.btn_colors {
  width: 200px;
  background-image: linear-gradient(#00536c, #007491);
  border-color: #005574;
}
.MyProfile-UserProfile-div1 {
  width: 19%;
}
.MyProfile-UserProfile-div2 {
  width: 80%;
}
.rating_applied_r_line_css {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: white;
  display: flex;
}
.rating_applied_r_number_css {
  font-size: 12px;
  margin: auto;
}

.e183m4hmemojiStyling>.e8k2yoa{
  height:34px !important;
  width:34px !important;;
  /* margin-top: auto !important;; */
}
.e13wqaj6 {
  border: none !important;
  background-color: #fff !important;
  font-size: 30px !important;
  width: 30px !important;
  color: rgb(201, 200, 205) !important;
  margin-top: -8px !important;
}
.e183m4hmemojiStyling>.e8k2yoa:hover {
  background-color: transparent;
}
.e183m4hmemojiStyling>.ejr02pv{
  right:-28px
}

.rating_applied_r_line_css_for_rating_view {
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  display: flex;
}
.rating_applied_r_number_css_for_rating_view {
  font-size: 9px;
  margin: 8px 8px 8px 5px;

}
.dh {
  margin-top: 10px;
}
.Birth_Anniversari_card>.ant-card-body{
  height:calc(100% - 60px)
}
.Edit_Modal_Layout>.ant-modal-content>.ant-modal-body{
  padding:9px 12px 16px;
}
.Block_modal>.ant-modal-content>.ant-modal-body{
  padding: 0px 24px 24px;
}
.Block_modal>.ant-modal-content>.ant-modal-footer{
  padding:0px 10px 16px
}
.Accept-request-modal>.ant-modal-content>.ant-modal-footer{
  padding:0px 10px 16px;
}
@media (min-width: 2080px) {
}

@media (max-width: 1115px) {
  .rating_applied_l {
    width: 26%;
  }
  .rating_applied_r {
    width: 71%;
  }
}
@media (max-width: 992px) {
  .rating_applied_l {
    width: 22%;
  }
  .rating_applied_r {
    width: 78%;
  }
}
@media (max-width: 576px) {
  .rating_applied_l {
    width: 30%;
  }
  .rating_applied_r {
    width: 68%;
  }
}

@media (max-width: 768px) {
  .profile_rating_flow_for_rating_view{
    padding:10px 25px 10px 10px !important
  }
  .rating_applied_r_line_css_for_rating_view {
    width: 35px;
    height: 35px;
  }
  .rating_applied_r_number_css_for_rating_view {
    font-size: 12px;
    margin: auto;
  }
}
@media(max-width:1340px){
  .MyBusiness_Download {
    width: 60%;
    font-size: 13.25px;
    line-height: 29px;
  }
  .MyBusiness_Update {
    width: 60%;
    font-size: 13.25px;
    margin-top: 10px;
  }
}

@media(max-width:1200px){
  .MyBusiness_Download {
    width: 39%;
    font-size: 13.25px;
    /* line-height: 29px; */
  }
  .MyBusiness_Update {
    width: 46%;
    font-size: 13.25px;
    margin-top: 0px;
  }
}
@media(max-width:1170px){
  .MyBusiness_Download {
    width: 60%;
    font-size: 13.25px;
    line-height: 29px;
  }
  .MyBusiness_Update {
    width: 60%;
    font-size: 13.25px;
    margin-top: 10px;
  }
}

@media(max-width:992px){
  .MyBusiness_Download {
    width: 39%;
    font-size: 13.25px;
    /* line-height: 29px; */
  }
  .MyBusiness_Update {
    width: 46%;
    font-size: 13.25px;
    margin-top: 0px;
  }
}

@media (max-width: 430px) {
  .rating_applied_l {
    width: 100%;
    margin-bottom: 8px;
  }

  .other_Rating > .profile_rating_flowx > .rating_applied_lx {
    width: 100%;
  }
  .MyProfile-UserProfile-div1 {
    width: 22%;
  }
  .MyProfile-UserProfile-div2 {
    width: 75%;
  }
  .avatar_rating_sent_received {
    margin-left: -45px;
  }
  .profile_rating_flow {
    display: flex;
    flex-direction: column;
  }
  .rating_applied_r {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    position: relative;
  }
  .rating_applied_lx {
    margin-bottom: 14px;
  }
  .profile_rating_flowx {
    display: block;
  }
  .other_Rating > .profile_rating_flowx > .rating_applied_rx {
    width: 100%;
  }
  .rating_applied_r_line_css {
    width: 28px;
    height: 28px;
  }
  .rating_applied_r_number_css {
    font-size: 9px;
    margin: 8px 8px 8px 5px;
  }
  .rating_applied_r_line_css_for_rating_view {
    width: 28px;
    height: 28px;
  }
  .rating_applied_r_number_css_for_rating_view {
    font-size: 9px;
    margin: 8px 8px 8px 5px;
  }
  .MyBusiness_Download {
    width: 47%;
    font-size: 13.5px !important ;
    /* line-height: 29px; */
  }
  .MyBusiness_Update {
    width: 52%;
    font-size: 13.5px !important;
    /* margin-top: 10px; */
  }
  .MyProfile_Dropdown_css{
    margin-top:10px
  }
}

@media (max-width: 330px) {
  .MyBusiness_Download {
    width: 75%;
    padding:4px;
    font-size: 13.5px !important ;
    /* line-height: 29px; */
  }
  .MyBusiness_Update {
    width: 75%;
    padding:4px;
    font-size: 13.5px !important;
    margin-top: 10px;
  }

  .Dropdown_image_myProfile{
    width:24px;height:24px
  }
}

@media (max-width: 360px) {
  .MyProfile-UserProfile-div1 {
    width: 28%;
  }
  .MyProfile-UserProfile-div2 {
    width: 70%;
  }
}

.rating_applied_r > .MuiBox-root > .MuiBox-root > .MuiTypography-caption {
  margin-left: -3px;
}

.NetworkComponent>.ant-card {
 height:100%;
}

.NetworkComponent>.ant-card >.ant-card-body{
 height:calc(100% - 60px)
}
.NetworkComponent>.ant-card >.ant-card-actions{
  height:60px
 }
