.searchStyles .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
}

.searchStyles .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.searchStyles .ant-tabs-ink-bar {
  background: #1890ff;
}

.searchStyles .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}

.searchStyles .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  text-align: center;
}

.listStyles {
  cursor: pointer;
}
